<mat-toolbar class="toolbar">
  <a *ngIf="currentUser.warningMessage !== 'Account deactivated'" [routerLink]="['/']" class="app-name">
    OpsEase
  </a>
  <a *ngIf="currentUser.warningMessage === 'Account deactivated'" href="#" class="app-name">
    OpsEase
  </a>
  <div class="spacer"></div>

  <mat-menu #userMenu="matMenu">
    <button
      *restrictAccess="['super admin', 'admin', 'member', 'user']"
      mat-menu-item routerLink="/personal-settings"
    >
      Personal settings
    </button>

    <button
      *restrictAccess="['super admin']"
      mat-menu-item routerLink="/admin/reseller-companies"
    >
      Reseller companies
    </button>

    <ng-container *restrictAccess="['super admin', 'admin']">
      <button
        *restrictAccess="['super admin']"
        mat-menu-item
        routerLink="/admin/customers"
      >
        Customers
      </button>

      <button *restrictAccess="['admin']" mat-menu-item [routerLink]="['/admin/customers/customer-settings']">
        Customer settings
      </button>

      <button mat-menu-item routerLink="/admin/users" *ngIf="currentUser.warningMessage !== 'Account deactivated'">
        Users
      </button>

      <button *restrictAccess="['super admin', 'admin']" mat-menu-item routerLink="/admin/audit">
        Audit log
      </button>

      <button *restrictAccess = "['super admin']" mat-menu-item routerLink="/admin/monitors">
        Monitors
      </button>

      <button mat-menu-item (click) = "getHelperMenus()" *ngIf = "!isHelperMenusPresent">
        Get Helper Menus
      </button>

      <a *restrictAccess="['super admin', 'admin', 'member', 'user']" mat-menu-item href="https://opsease.zendesk.com/" target="_blank">
        Support
      </a>
    </ng-container>

    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>

  <div class="d-inline-flex align-center">
    <a
      [routerLink]="['/controls/pending']"
      *restrictAccess="['super admin', 'admin', 'user']"
      class="notification-bell"
      (click) = "resetNotificationsCount()"
    >
      <mat-icon [matBadge] = "notificationsCount | async" matBadgeColor = "warn">notifications</mat-icon>
    </a>

    <span class="user" [matMenuTriggerFor]="userMenu">
      {{ currentUser.name }}

      <mat-icon>arrow_drop_down</mat-icon>
    </span>
  </div>
</mat-toolbar>
