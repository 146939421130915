import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HelperMenu } from 'src/app/core/models/HelperMenu';
import { addHelperMenu, clearHelperMenu } from 'src/app/Store/Actions/helper-menu.action';
import { HelperMenuState } from 'src/app/Store/Reducers/helper-menu.reducer';
@Component({
    selector: 'app-ui-helper-component',
    templateUrl: './ui-helper.component.html',
    styleUrls: ['./ui-helper.component.scss']
})
export class UIHelperComponent {
    tipFor: string;
    helperMenu$ = this.store.select('helperMenus');
    nextMenu$: HelperMenu = null;
    currentMenu$: HelperMenu = null;
    currentEntry: number = null;
    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<UIHelperComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store<HelperMenuState>
    ) {
        this.tipFor = data.tipFor;
        this.deterMineActionButtons();
    }

    deterMineActionButtons() {
        const user = localStorage.getItem('user');
        const helperMenus = JSON.parse(user).expectedHelperMenus;
        const activeHelper = JSON.parse(localStorage.getItem('activeHelper'));
        this.nextMenu$ = helperMenus[activeHelper.entry];
        this.currentEntry = activeHelper.entry;
        this.currentMenu$ = activeHelper;
    }
    async emitNextTipAndNavigateBack(tip, route) {
        if (route) {
            await this.router.navigate([`/${route}`]);
        }
        this.dialogRef.close();
    }

    async emitNextTipAndNavigate(menu: HelperMenu) {
        if (menu.route) {
            await this.router.navigate([`/${menu.route}`]);
            this.addPageToStore(menu.page, menu.route, menu.entry);
        } else {
            this.addPageToStore(this.nextMenu$.page, this.nextMenu$.route, this.nextMenu$.entry);
        }
        this.dialogRef.close();
    }

    addPageToStore(page: string, route: string, entry: number,
                   children?: [HelperMenu]) {
        this.store.dispatch(addHelperMenu(
            {
                page,
                route,
                entry,
                children: children ? children : null,
            }
        ));
        this.dialogRef.close();
    }
    skip() {
        this.store.dispatch(clearHelperMenu());
        localStorage.removeItem('helperMenus');
        localStorage.removeItem('activeHelper');
        const user = JSON.parse(localStorage.getItem('user'));
        user.expectedHelperMenus = [];
        localStorage.setItem('user', JSON.stringify(user));
        this.dialogRef.close();
    }

    next() {
        localStorage.setItem('activeHelper', JSON.stringify(this.nextMenu$));
        if (this.nextMenu$.route !== '/') {
            this.emitNextTipAndNavigate(this.nextMenu$);
        }
    }

    back() {
        const user = localStorage.getItem('user');
        const helperMenus = JSON.parse(user).expectedHelperMenus;
        const activeHelper = JSON.parse(localStorage.getItem('activeHelper'));
        if (activeHelper.entry === 1) {
            // no back. End navigation
        } else {
            const previousMenu = helperMenus[activeHelper.entry - 2];
            if (previousMenu) {
                localStorage.setItem('activeHelper', JSON.stringify(previousMenu));
                if (previousMenu.route !== '/') {
                    this.emitNextTipAndNavigateBack(previousMenu.page, previousMenu.route);
                }
                this.dialogRef.close();
                this.addPageToStore(previousMenu.page, previousMenu.route, previousMenu.entry);
            }
        }
    }
}
