import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { User } from '../../../core/models/User';
import { UserService } from '../../../core/services/user.service';
import { Observable, Subscription } from 'rxjs';
import { ResellersService } from '../../../resellers/resellers.service';
import { Customer } from '../../../core/models/Customer';
import { ControlsService } from 'src/app/controls/controls.service';
import { HelperMenuState } from '../../../Store/Reducers/helper-menu.reducer';
import { addHelperMenu } from '../../../Store/Actions/helper-menu.action';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public currentUser: User;
  public customerId: string;
  public notificationsCount: Observable<number>;
  public customers: Customer[] = [];
  subscription: Subscription;
  subscriptions: Subscription[] = [];
  public isHelperMenusPresent = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private controlsService: ControlsService,
  ) {
    this.fetchUpdatedUser();
    this.fetchPendingNotifications();
  }

  fetchUpdatedUser() {
    this.subscriptions.push(
      this.userService.updatedUser
        .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.customerId = this.currentUser && this.currentUser.customer ? this.currentUser.customer.id : null;
          }
        }
        )
    );
  }

  fetchPendingNotifications() {
    this.subscriptions.push(
      this.controlsService.index(0, 20, { filter: 'pending' }).subscribe(resp => {
        if (resp.total) {
          this.controlsService.setDueControls(resp.total);
          this.notificationsCount = this.controlsService.getDueControls();
          // sessionStorage.setItem('notificationsCount', resp.total);
        }
      })
    );
  }

  resetNotificationsCount() {
    this.controlsService.setDueControls('');
    this.notificationsCount = this.controlsService.getDueControls();
  }

  isHelperMenuPresent() {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    const helperMenus = userDetails.expectedHelperMenus;
    if (!helperMenus) {
      this.isHelperMenusPresent = false;
    } else if (!helperMenus.length) {
      this.isHelperMenusPresent = false;
    } else {
      this.isHelperMenusPresent = true;
    }
  }

  getHelperMenus() {
    this.subscriptions.push(
      this.userService.fetchHelperMenus().subscribe(resp => {
        this.userService.setHelperMenus(resp);
      })
    );
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authService
      .logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/login']).then(() => {
          location.reload();
        });
      });
  }
  ngOnInit() {
    this.currentUser = this.userService.currentUserValue;
    this.customerId = this.currentUser && this.currentUser.customer ? this.currentUser.customer.id : null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
