import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/User';
import { environment } from '../../../environments/environment';
import { UserRoles } from '../helpers/user-roles.enum';
import { Packages } from '../../core/enums/packages.enum';
import { Customer } from '../models/Customer';

@Injectable({ providedIn: 'root' })
export class UserHeaderService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  // Observable user source
  private userSource = new BehaviorSubject<User>(null);

  // Observable user stream
  updatedUser = this.userSource.asObservable();

  constructor(private http: HttpClient) {
    const user = localStorage.getItem('user');

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(user));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    const customerOwner: Customer | null = JSON.parse(sessionStorage.getItem('fullCustomerObject'));
    if (customerOwner === null) {
      return this.currentUserSubject.value;
    }
    return customerOwner;
  }

  public get isCurrentUserSuperAdmin(): boolean {
    return this.currentUserValue.role.id === UserRoles.SuperAdmin;
  }

  public get isCurrentUserAdmin(): boolean {
    const chosenUser = sessionStorage.getItem('fullCustomerObject');
    const result = this.currentUserValue.role.id === UserRoles.Admin || chosenUser !== null;
    return result;
  }

  public get isCurrentUserUser(): boolean {
    return this.currentUserValue.role.id === UserRoles.User;
  }

  public get isCurrentUserMember(): boolean {
    return this.currentUserValue.role.id === UserRoles.Member;
  }

  public get isCurrentUserReseller(): boolean {
    return this.currentUserValue.role.id === UserRoles.Reseller;
  }

  private get customerPackageIdFromLocalStorage(): number | null {
    const customerOwner: Customer | null = JSON.parse(sessionStorage.getItem('fullCustomerObject'));
    return (customerOwner && customerOwner?.package?.id) || null;
  }
  public get isCustomerEnterprise(): boolean {
    const customerOwner: Customer | null = JSON.parse(sessionStorage.getItem('fullCustomerObject'));
    return (this.currentUserValue?.customer?.package?.id === Packages.Enterprise) ||
      (this.customerPackageIdFromLocalStorage === Packages.Enterprise);
  }

  public get isCustomerPro(): boolean {
    return this.currentUserValue.customer.package.id === Packages.Pro;
  }

  public get isCustomerFree(): boolean {
    return (this.currentUserValue?.customer?.package?.id === Packages.Free) ||
      (this.customerPackageIdFromLocalStorage === Packages.Free);
  }

  getAll() {
    return this.http.get<any>(`${environment.apiUrl}/users`).pipe(map(users => {
      return users;
    }));
  }

  getUser(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/users/${id}`).pipe(map(user => {
      return user;
    }));
  }

  addUser(user: any) {
    return this.http.post<any>(`${environment.apiUrl}/users`, user).pipe(map(u => u));
  }

  reInviteUser({ email: userEmail }) {
    return this.http.post<any>(`${environment.apiUrl}/users/reinvite`, { email: userEmail }).pipe(map(u => u));
  }

  updateUser(id: string, data: any) {
    return this.http.put<any>(`${environment.apiUrl}/users/${id}`, data).pipe(map(u => u));
  }

  getUpdatedUser(user: User) {
    this.userSource.next(user);
  }

  deleteUser(id: string, data: any = {}) {
    const options = Object.keys(data).length > 0 ? {
      params: data,
    } : {};
    return this.http.delete<any>(`${environment.apiUrl}/users/${id}`, options).pipe(map(response => {
      return response;
    }));
  }

  deleteReseller(id: string, data: any = {}) {
    const options = Object.keys(data).length > 0 ? {
      params: data,
    } : {};
    return this.http.post<any>(`${environment.apiUrl}/customers/remove-reseller/${id}`, options).pipe(map(u => u));
  }

  getInvited() {
    return this.http
      .get<any>(`${environment.apiUrl}/users/invited`)
      .pipe(map(users => users));
  }

  deleteInvitation(id: string, data: any = {}) {
    const options = Object.keys(data).length > 0 ? {
      params: data,
    } : {};
    return this.http
      .delete<any>(`${environment.apiUrl}/users/invited/${id}`, options)
      .pipe(map(response => response));
  }

  public setUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));

    this.currentUserSubject.next(user);
  }

  public clearUser() {
    this.currentUserSubject.next(null);
  }
}
