<section class="section">
  <mat-card class="signup-box">
    <h1>Sign Up</h1>

    <form class="signup-form" [formGroup]="signUpForm" (ngSubmit)="submit()">
      <mat-error class="signup-error" *ngIf="apiError">{{ apiError }}</mat-error>

      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput name="name" formControlName="name" required id = "name-search">
        <mat-error *ngIf="name.invalid && (name.dirty || name.touched) && name.errors.required">
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" required id = "name-search">
        <mat-error *ngIf="password.invalid && (password.dirty || password.touched) && password.errors.required">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <button class="signup-button" type="submit" mat-raised-button color="primary" [disabled]="!signUpForm.valid">
        Sing Up
      </button>
    </form>
  </mat-card>
</section>