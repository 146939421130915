export const environment = {
    production: false,
    apiUrl: 'https://api-stage.opsease.com',
    stripe: {
      publicKey: 'pk_test_51HtPXKKjwanizaAdwt7zgHoRfHXtxnObQ6ab4g6uqv8daxtJyXvZGiQdArzKkZx6G3yCpKMejhjPSNR6mm4l2axb00NqrpE0rR',
    },
    siteKey: '6LeSBPgbAAAAAOvEvKyzEqZUfNS7HaN8Nwd0B7yH',

    jiraAuthorizationLink: {
      authorize: 'https://auth.atlassian.com/authorize?',
      audience: 'audience=api.atlassian.com&',
      clientId: 'client_id=ojVQ45fIaCB2IwcR2teeCUxN68sYQNwh&', // The App Client ID,
      scope: 'scope=offline_access%20read%3Ajira-user%20read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20write%3Ajira-work%20manage%3Ajira-webhook%20manage%3Ajira-data-provider&',
      redirectUrl: 'redirect_uri=https%3A%2F%2Fstage.opsease.com%2Fadmin%2Fcustomers%2Fcustomer-settings&',
      state: 'state=',
      response: '&response_type=code&prompt=consent'
    },
     
    threatModuleJiraAuthorizationLink: {
      authorize: 'https://auth.atlassian.com/authorize?',
      audience: 'audience=api.atlassian.com&',
      clientId: 'client_id=OATGsDJOYRhHKGf5FkijPhGqORimQVi4&',
      scope: 'scope=offline_access%20read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-configuration%20read%3Ajira-user%20write%3Ajira-work%20manage%3Ajira-webhook%20manage%3Ajira-data-provider&',
      redirectUrl: 'redirect_uri=https%3A%2F%2Fstage.opsease.com%2Fthreats&',
      state: 'state=',
      response: '&response_type=code&prompt=consent'
    }
  };