import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { DomainService } from '../services/domain.service';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { Domain } from '../models/Domain';

@Injectable()
export class DomainResolver implements Resolve<any> {
  router: any;
  constructor(private domainService: DomainService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Domain> {
    return this.domainService.getAll(route.paramMap.get('id'));
  }
}
