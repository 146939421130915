<div *ngIf="httpProgressBarService.isLoading | async">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<section class="section">
  <mat-card class="login-box">
    <h1>Login</h1>
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-error class="login-error" *ngIf="loginError">{{ loginError }}</mat-error>

      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input matInput name="email" formControlName="email" required />
        <mat-error *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" required />
        <mat-error *ngIf="password.invalid && (password.dirty || password.touched) && password.errors.required">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <button class="login-button" type="submit" mat-raised-button color="primary" [disabled]="!loginForm.valid">
        Login
      </button>
      <button class="forgot-password" type="button" mat-raised-button color="primary" (click)="goToForgotPassword()"> Forgot Password? </button>
    </form>
  </mat-card>
</section>
