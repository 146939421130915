import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuditService {

  constructor(private http: HttpClient) { }

  index(currentPage = 0, pageSize = 20, customer = null) {
    let params = new HttpParams()
      .set('page', currentPage.toString())
      .set('per', pageSize.toString());

    if (customer) {
      params = params.set('customer', customer);
    }

    return this.http
      .get<any>(`${environment.apiUrl}/audit`, { params })
      .pipe(map(response => response));
  }
}
