import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import keysToCamel from '../core/helpers/keys-to-camel';

@Injectable({ providedIn: 'root' })
export class AssessmentsService {
  constructor(private http: HttpClient) { }

  index(currentPage = 1, pageSize = 20) {
    return this.http
      .get<any>(`${environment.apiUrl}/assessments`, {
        params: new HttpParams()
          .set('page', currentPage.toString())
          .set('per', pageSize.toString())
      })
      .pipe(map(response => {
        response.results = response.results.map((assessment) => keysToCamel(assessment));

        return response;
      }));
  }

  save(vendorId: string, data: any) {
    return this.http
      .put<any>(`${environment.apiUrl}/vendors/${vendorId}/assessment`, data)
      .pipe(map((response) => response));
  }

  uploadFile(vendorId: string, assessmentId: string, data: FormData) {
    return this.http
      .post<any>(`${environment.apiUrl}/vendors/${vendorId}/assessment/${assessmentId}/files`, data)
      .pipe(map((assessment) => keysToCamel(assessment)));
  }
}
