import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthStrategy, AUTH_STRATEGY } from './auth.strategy';
import { User } from '../../core/models/User';
import { UserRole } from '../../core/models/UserRole';
import { Customer } from 'src/app/core/models/Customer';
import { UserService } from '../../core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly INITIAL_PATH = '/dashboard';
  public readonly LOGIN_PATH = '/login';

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    @Inject(AUTH_STRATEGY) private auth: AuthStrategy<any>
  ) {}

  login(loginRequest: any): Observable<User> {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/login`, loginRequest)
      .pipe(tap((user) => this.auth.doLoginUser(user)))
      .pipe(tap((user) => this.userService.setUser(user)))
      .pipe(tap((user) => this.userService.setAuxilliaryPackages(user.subscriptions)));
  }

  logout() {
    sessionStorage.clear();
    return this.http
      .post<any>(`${environment.apiUrl}/auth/logout`, {})
      .pipe(tap(() => this.doLogoutUser()))
      .pipe(tap(() => this.userService.clearUser()));
  }

  isLoggedIn$(): Observable<boolean> {
    return this.auth.getCurrentUser().pipe(
      map((user) => !!user),
      catchError(() => of(false))
    );
  }

  getCurrentUser$(): Observable<User> {
    return this.auth.getCurrentUser();
  }

  getUserRole$(): Observable<UserRole> {
    return this.auth.getCurrentUser().pipe(map((user) => user.role));
  }

  getUserCustomer$(): Observable<Customer> {
    return this.auth.getCurrentUser().pipe(map((user) => user.customer));
  }

  doLogoutAndRedirectToLogin() {
    this.doLogoutUser();
    this.router.navigate(['/login']);
  }

  registerInvitedUser(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/auth/register-invitation`, data);
  }
  activateAccount(token: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/activate-account`, {params: {token}});
  }

  private doLogoutUser() {
    this.auth.doLogoutUser();
  }

  signUp(signupRequest: any) {
    return this.http.post<any>(`${environment.apiUrl}/signup`, signupRequest);
  }

  sendEmailForgotPassword(email: { email: string }) {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/forgot-password`, email);
  }

  resetPassword(data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/auth/reset-password/` + data.token, data);
  }
}


