<section class="section">
  <div class="section-header">
    <h1>Personal settings</h1>
  </div>

  <form class="user-form" [formGroup]="form" (ngSubmit)="update()" autocomplete="off" name="lastpass-disable-search" id = "lastpass-disable-search">
    <div>
      <mat-form-field [floatLabel]="options.value.floatLabel">
        <mat-label>Name</mat-label>
        <input matInput name="name" formControlName="name" data-lpignore="true" id = "name-search"/>
      </mat-form-field>
    </div>

    <div>
      <mat-accordion>
        <mat-expansion-panel #matExpansionPanel>
          <mat-expansion-panel-header>
            <mat-panel-title>Change Password</mat-panel-title>
          </mat-expansion-panel-header>

          <mat-form-field [floatLabel]="options.value.floatLabel">
            <mat-label>Current Password</mat-label>
            <input type="password" matInput name="currentPassword" formControlName="currentPassword" data-lpignore = "true" id = "currentPassword-search"/>
          </mat-form-field>

          <mat-form-field [floatLabel]="options.value.floatLabel">
            <mat-label>New Password</mat-label>
            <input
              type="password"
              matInput
              name="password"
              formControlName="password"
              data-lpignore="true"
              id = "password-search"
            />
          </mat-form-field>

          <mat-form-field [floatLabel]="options.value.floatLabel">
            <mat-label>Retype New Password</mat-label>
            <input
              type="password"
              matInput
              name="confirmPassword"
              formControlName="confirmPassword"
              [errorStateMatcher]="matcher"
              data-lpignore="true"
              id = "confirmPassword-search"
            />
            <mat-error *ngIf="form.hasError('notSame')">
              Password doesn't match.
            </mat-error>
          </mat-form-field>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="mt-40" *restrictAccess="['super admin', 'admin', 'user', 'member'];packages:['enterprise', 'pro']">
      <h3>Notifications</h3>

      <div class="notification-settings">
        <span>Email reminders for recurring controls.</span>
        <mat-slide-toggle formControlName="enableControlNotifications"></mat-slide-toggle>
      </div>
    </div>

    <div class="form-controls mt-30">
      <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid">
        Save
      </button>
    </div>
  </form>
</section>
