import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpProgressBarService } from 'src/app/shared/services/httpprogress.service';
import { AuthService } from '../../services/auth.service';
@Component({
    selector: 'app-activate-account-component',
    templateUrl: './activate.account.component.html',
    styleUrls: ['./activate.account.component.scss']
})

export class ActivateAccountComponent implements OnInit {
    activateToken: string;
    public apiError: string;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        public httpProgressBarService: HttpProgressBarService
        )  {

    }

    activate() {
        this.authService.activateAccount(this.activateToken).pipe(first()).subscribe(() => {
            this.router.navigate(['/login']);
        },
        (error) => {
            this.apiError = error.error.message;
        });
    }
    ngOnInit() {
        this.activateToken = this.route.snapshot.params.token;
        setTimeout(() => this.activate(), 2000);
    }

}
