import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { User } from '../../../core/models/User';
import { Observable, Subscription } from 'rxjs';
import { ResellersService } from '../../../resellers/resellers.service';
import { Customer } from '../../../core/models/Customer';
import { UserHeaderService } from '../../../core/services/user-header.service';
import { ControlsService } from 'src/app/controls/controls.service';
import { UserService } from 'src/app/core/services/user.service';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-reseller-header',
  templateUrl: './reseller-header.component.html',
  styleUrls: ['./reseller-header.component.scss']
})
export class ResellerHeaderComponent implements OnInit, OnDestroy {
  public currentUser: User;
  public customerId: string;
  public customers: Customer[] = [];
  public preparedObj: Customer;
  public notificationsCount: Observable<number>;
  subscription: Subscription;
  subscriptions: Subscription[] = [];
  public isHelperMenusPresent = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userHeaderService: UserHeaderService,
    private resellersService: ResellersService,
    private controlsService: ControlsService,
    private userService: UserService
  ) {
    this.subscriptions.push(
      this.userHeaderService.updatedUser
        .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.customerId = this.currentUser && this.currentUser.customer ? this.currentUser.customer.id : null;
          }
        }
        )
    );
  }

  fetchPendingNotifications() {
    this.subscriptions.push(
      this.controlsService.index(0, 20, { filter: 'pending' }).subscribe(resp => {
        if (resp.total) {
          this.controlsService.setDueControls(resp.total);
          this.notificationsCount = this.controlsService.getDueControls();
        }
      })
    );
  }

  resetNotificationsCount() {
    this.controlsService.setDueControls('');
    this.notificationsCount = this.controlsService.getDueControls();
  }

  isHelperMenuPresent() {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    // userDetails = JSON.parse(userDetails);
    const helperMenus = userDetails.expectedHelperMenus;
    if (!helperMenus) {
      this.isHelperMenusPresent = false;
    } else if (!helperMenus.length) {
      this.isHelperMenusPresent = false;
    } else {
      this.isHelperMenusPresent = true;
    }
  }

  getHelperMenus() {
    this.subscriptions.push(
      this.userService.fetchHelperMenus().subscribe(resp => {
        this.userService.setHelperMenus(resp);
      })
    );
  }

  ngOnInit() {
    const retrievedUserObjectFromLocalStorage = localStorage.getItem('user');
    const userInLocalStorage = JSON.parse(retrievedUserObjectFromLocalStorage);

    const retrievedUserObjectFromSessionStorage = sessionStorage.getItem('fullCustomerObject');
    const userInSessionStorage = JSON.parse(retrievedUserObjectFromSessionStorage);

    if (userInLocalStorage && userInSessionStorage) {
      this.currentUser = userInLocalStorage;
      this.customerId = userInSessionStorage.customer.id;
    }
    else {
      this.currentUser = this.userHeaderService.currentUserValue;
      this.customerId = this.currentUser && this.currentUser.customer ? this.currentUser.customer.id : null;
    }

    if (this.currentUser.role.id === 6) {
      this.loadResellerCustomers();
    }
    this.fetchPendingNotifications();
  }

  loadResellerCustomers() {
    this.resellersService.getAllResellerUsers()
      .pipe(first())
      .subscribe((results) => {
        results.forEach(result => {
          result.userCustomers.forEach(userCustomer => {
            this.customers.push(userCustomer);
          });
        });
      });
  }

  selectCustomer(customer) {
    sessionStorage.setItem('customerObject', JSON.stringify(customer));
    const user = JSON.parse(localStorage.getItem('user'));
    let helperMenus = JSON.parse(localStorage.getItem('user')).expectedHelperMenus;
    // if customer is not enterprise package, trim some helpers. Refer to exhaustive menus for each category of users
    if (customer.package_id !== 3) {
      helperMenus = helperMenus.filter(page => page.entry !== 5 && page.entry !== 6);
      helperMenus = helperMenus.map((menu, index) => {
        menu.entry = index + 1;
        return menu;
      });
      user.expectedHelperMenus = helperMenus;
      localStorage.setItem('user', JSON.stringify(user));
    }
    this.resellersService.getSelectedResellerUser()
      .pipe(first())
      .subscribe(async (result) => {
        this.preparedObj = result;
        sessionStorage.setItem('fullCustomerObject', JSON.stringify(this.preparedObj));
        await this.router.navigate(['/']);
        location.reload();
      });
  }

  navigateToResellerDashboard() {
    sessionStorage.removeItem('fullCustomerObject');
    sessionStorage.removeItem('customerObject');
    this.router.navigate(['/reseller']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  logout() {
    if (sessionStorage.getItem('customerObject')) {
      sessionStorage.removeItem('customerObject');
    }
    if (sessionStorage.getItem('fullCustomerObject')) {
      sessionStorage.removeItem('fullCustomerObject');
    }
    this.authService
      .logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/login']).then(() => {
          location.reload();
        });
      });
  }
}
