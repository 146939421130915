export enum Frequencies {
  Automated = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
  Quarterly = 5,
  Yearly = 6,
  TwoMonths = 7,
  SemiYearly = 8
}
