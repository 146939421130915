import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { UIHelperComponent } from '../ui-helper/ui-helper.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../../core/models/User';
import { tip_target, UserService } from '../../../core/services/user.service';
import { AssessmentsService } from '../../../assessments/assessments.service';
import { first } from 'rxjs/operators';
import { UserHeaderService } from '../../../core/services/user-header.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserRoles } from '../../../core/helpers/user-roles.enum';
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { Store } from '@ngrx/store';
import { HelperMenuState } from '../../../Store/Reducers/helper-menu.reducer';
import { addHelperMenu } from '../../../Store/Actions/helper-menu.action';
import { HttpProgressBarService } from 'src/app/shared/services/httpprogress.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {
  public currentUser: User;
  public isAdmin: boolean;
  public opened: boolean;
  public isExpanded = false;
  public assessments: number;
  public isAccountDeactivated = false;
  current_tip_target = null;
  helper_menu_number = null;

  @ViewChild('sidenav') sidenav: MatSidenav;
  userActivity;
  userInactive: Subject<any> = new Subject();
  helperMenu$ = this.store.select('helperMenus');
  constructor(private userService: UserService,
              private userHeaderService: UserHeaderService,
              private assessmentsService: AssessmentsService,
              private snackBar: MatSnackBar,
              private router: Router,
              private authService: AuthService,
              public dialog: MatDialog,
              private store: Store<HelperMenuState>,
              public httpProgressBarService: HttpProgressBarService) {
    this.setTimeout();
    this.userInactive.subscribe(() => this.logout());
    this.renderHelperMenus();
  }

  renderHelperMenus() {
    const user = localStorage.getItem('user');
    // if user is reseller and no customer has been selected, do not show helper menus
    if (JSON.parse(user).role_id === 6 && !sessionStorage.getItem('customerObject')) {
      return;
    }
    const helperMenus = JSON.parse(user).expectedHelperMenus;
    if (!helperMenus) {
      return;
    }
    if (helperMenus.length) {
      if (localStorage.getItem('activeHelper')) {
        const activeHelper = JSON.parse(localStorage.getItem('activeHelper'));
        this.store.dispatch(addHelperMenu(
          {
            page: activeHelper.page,
            route: activeHelper.route,
            entry: activeHelper.entry
          }
        ));
      } else {
        this.store.dispatch(addHelperMenu(
          {
            page: helperMenus[0].page,
            route: helperMenus[0].route,
            entry: helperMenus[0].entry
          }
        ));
        localStorage.setItem('activeHelper', JSON.stringify(helperMenus[0]));
      }
    }

  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1000 * 60 * 60 * 24 * 3); // 72 hrs
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  logout() {
    sessionStorage.clear();
    this.authService
      .logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/login']).then(() => {
          location.reload();
        });
      });
  }

  subscribeToHelperEmitters() {
    this.helperMenu$.subscribe(entries => {
      const val = entries[entries.length - 1];
      if (!val) {
        return;
      }
      this.current_tip_target = val.page;
      this.helper_menu_number = val.entry;
      this.dialog.open(UIHelperComponent, {
        data: {
          tipFor: val.page
        },
        disableClose: true,
        height: 'auto',
        width: 'auto'
      });
      this.dialog.afterAllClosed.subscribe(() => this.helper_menu_number = null);
    }
    );
  }

  close() {
    this.sidenav.close();
  }

  public showSidebar() {
    this.isExpanded = true;
  }

  public hideSidebar() {
    this.isExpanded = false;
  }

  load(pageIndex = 0, pageSize = 50) {
    return this.assessmentsService
      .index(pageIndex, pageSize)
      .pipe(first())
      .subscribe((response) => {
        this.assessments = response.total;
      });
  }
  ngOnInit() {
    this.subscribeToHelperEmitters();
    const retrievedUserObjectFromLocalStorage = localStorage.getItem('user');
    const userInLocalStorage = JSON.parse(retrievedUserObjectFromLocalStorage);

    const retrievedUserObjectFromSessionStorage = sessionStorage.getItem('fullCustomerObject');
    const userInSessionStorage = JSON.parse(retrievedUserObjectFromSessionStorage);

    if (userInLocalStorage && userInSessionStorage) {
      this.currentUser = userInLocalStorage;
    }
    else {
      this.currentUser = this.userHeaderService.currentUserValue;
    }

    this.isAdmin = this.currentUser.role.id === UserRoles.Admin;
    if (this.currentUser.warningMessage === 'Account deactivated') {
      this.isAccountDeactivated = true;
    }
    else {
      this.isAccountDeactivated = false;
    }
    if (this.currentUser.warningMessage && this.currentUser.warningMessage === 'Account is about to be deactivated') {
      this.snackBar.open('Please contact account owner to update payment information', 'Dismiss', {
        duration: undefined,
      });
    }

    else if (this.userService.isCurrentUserAdmin
      && this.currentUser.warningMessage
      && this.currentUser.warningMessage === 'Account deactivated') {
      this.router.navigate([`/admin/customers/${this.currentUser.customer.id}`]);
    }
    else if (!this.userService.isCurrentUserAdmin
      && this.currentUser.warningMessage
      && this.currentUser.warningMessage === 'Account deactivated') {
      this.snackBar.open('Please contact account owner to update payment information', 'Dismiss', {
        duration: undefined
      });
    }
    this.load();
  }
}
