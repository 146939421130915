<div *ngIf = "tipFor === 'programs'" style="font-size:larger; font-weight: 400">
    <h4>Programs</h4>
    <span>To create, view and update programs click Programs menu shown</span><br>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>
<div *ngIf = "tipFor === 'create_program'">
    <h4>Programs workflow</h4>
    <span>Watch to learn how to create programs, domains and controls</span><br>
    <mat-tab-group>
        <mat-tab label="1. Create new program" matBadge = "1" matBadgePosition = "above after">
            <img src = "https://opseasestorage.blob.core.windows.net/assets/Program_1.gif"/>
        </mat-tab>
        <mat-tab label="2. Create Domain and add controls" matBadge = "2" matBadgePosition = "above after">
            <img src = "https://opseasestorage.blob.core.windows.net/assets/Program_2.gif"/>
        </mat-tab>
        <mat-tab label="3. Edit control" matBadge = "3" matBadgePosition = "above after">
            <img src = "https://opseasestorage.blob.core.windows.net/assets/Program_3.gif"/>
        </mat-tab>
    </mat-tab-group>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>
<div *ngIf = "tipFor === 'incidents-risks'">
    <h4>Risk Tracker</h4>
    <span>Create and manage Incidences/Risks from here</span><br>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>
<div *ngIf = "tipFor === 'documents'">
    <h4>Documents</h4>
    <span>Manage your files here</span><br>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>
<div *ngIf = "tipFor === 'controls_manager'">
    <h4>Controls Manager</h4>
    <span>Create customer programs for use in the Programs module or create customer Programs for use in the “Vendors” Section</span><br>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>
<div *ngIf = "tipFor === 'vendors'">
    <h4>Vendors</h4>
    <span>Create and manage vendors here</span><br>
    <img src = "https://opseasestorage.blob.core.windows.net/assets/Vendor.gif"/>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>
<div *ngIf = "tipFor === 'risk-score'">
    <h4>Risk Score</h4>
    <span>Program risk scores are calculated every 24 hours to determine risk score.</span><br>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>
<div *ngIf = "tipFor === 'threats'">
    <h4>Threat Module</h4>
    <span>This package enables you watch for and manage potential threats listed by NIST</span><br>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>
<div *ngIf = "tipFor === 'master_controls'">
    <h4>Master Control List</h4>
    <span>View and manage all controls here</span><br>
    <div class = "action-buttons">
        <button *ngIf = "currentEntry !== 1" mat-raised-button color = "primary" (click) = "back()">
            Back
        </button>
        <button *ngIf = "nextMenu$" mat-raised-button color = "primary" (click) = "next()">
            Next
        </button>
        <button mat-raised-button color = "primary" (click) = "skip()">
            Close
        </button>
    </div>
</div>