<app-reseller-header *ngIf="currentUser && currentUser.role.id === 6"></app-reseller-header>
<app-header *ngIf="currentUser && currentUser.role.id !== 6"></app-header>
<mat-sidenav-container *restrictAccess="['super admin', 'admin', 'user', 'member']" class="main"
  (backdropClick)="close()">
  <mat-sidenav *ngIf="!isAccountDeactivated" #sidenav mode="side" opened="true" disableClose
    (mouseenter)="showSidebar()" (mouseleave)="hideSidebar()" (keydown.escape)="hideSidebar()">
    <mat-nav-list>
      <mat-list-item routerLink="/">
        <mat-icon mat-list-icon>signal_cellular_alt</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Dashboard</h4>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user']" routerLink="/programs">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="small" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'programs'">check
        </mat-icon>
        <mat-icon mat-list-icon *ngIf="current_tip_target !== 'programs'">check</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Programs</h4>
      </mat-list-item>
      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages:['enterprise', 'pro']"
        routerLink="/incidents-risks">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="small" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'incidents-risks'">speed
        </mat-icon>
        <mat-icon mat-list-icon *ngIf="current_tip_target !== 'incidents-risks'">speed</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Incident/Risk Tracker</h4>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages:['enterprise', 'pro']"
        routerLink="/documents">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="small" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'documents'">policy
        </mat-icon>
        <mat-icon mat-list-icon *ngIf="current_tip_target != 'documents'">policy</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Documents</h4>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages:['enterprise']"
        routerLink="/programs/controls-manager">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'controls_manager'">
          backup_table
        </mat-icon>
        <mat-icon mat-list-icon *ngIf="current_tip_target !== 'controls_manager'">backup_table</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Controls Manager</h4>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages:['enterprise']" routerLink="/vendors">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="small" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'vendors'">assignment
        </mat-icon>
        <mat-icon mat-list-icon *ngIf="current_tip_target != 'vendors'">assignment</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Vendors</h4>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin', 'admin', 'user'];packages: ['enterprise', 'pro', 'free']"
        routerLink="/risk-score">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'risk-score'">
          score
        </mat-icon>
        <mat-icon *ngIf="current_tip_target != 'risk-score'" mat-list-icon>score</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Risk Score</h4>
      </mat-list-item>
      <mat-list-item
        *restrictAccess="['super admin', 'admin', 'user', 'reseller'];packages: ['enterprise', 'pro', 'free'];auxilliaryPackages:['Threat Module']"
        routerLink="/threats">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'threats'">
          shield
        </mat-icon>
        <mat-icon mat-list-icon *ngIf="current_tip_target != 'threats'">shield</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Threat Module</h4>
      </mat-list-item>

      <mat-list-item *restrictAccess="['super admin']" routerLink="/programs/master-controls">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'master_controls'">
          source
        </mat-icon>
        <mat-icon mat-list-icon *ngIf="current_tip_target != 'master_controls'">source</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Master Control List</h4>
      </mat-list-item>
      <mat-list-item *restrictAccess="['super admin']" routerLink="/customer-support">
        <mat-icon mat-list-icon [matBadge]="helper_menu_number" matBadgeSize="medium" matBadgeOverlap="false"
          matBadgePosition="above before" matBadgeColor="warn" *ngIf="current_tip_target === 'customer-support'">
          help-center
        </mat-icon>
        <mat-icon mat-list-icon *ngIf="current_tip_target != 'customer-support'">help_center</mat-icon>
        <h4 *ngIf="isExpanded" mat-line>Customer Support</h4>
      </mat-list-item>
      <ng-template [ngIf]="assessments > 0">
        <mat-list-item
          *restrictAccess="['super admin', 'admin', 'user', 'member'];packages:['enterprise', 'pro', 'free']"
          routerLink="/assessments">
          <mat-icon mat-list-icon>assessment</mat-icon>
          <h4 *ngIf="isExpanded" mat-line>Vendor Assessments</h4>
        </mat-list-item>
      </ng-template>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <div *ngIf="httpProgressBarService.isLoading | async">
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<div *restrictAccess="['vendor']">
  <div *ngIf="httpProgressBarService.isLoading | async">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>
  <router-outlet></router-outlet>
</div>

<div *restrictAccess="['reseller']">
  <div *ngIf="httpProgressBarService.isLoading | async">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>
  <router-outlet></router-outlet>
</div>