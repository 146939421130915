import { createReducer, on } from '@ngrx/store';
import { HelperMenu } from 'src/app/core/models/HelperMenu';
import { getHelperMenus, addHelperMenu, clearHelperMenu } from '../Actions/helper-menu.action';

export interface HelperMenuState {
    helperMenus: ReadonlyArray<HelperMenu>;
}

const initialState: ReadonlyArray<HelperMenu> = [];

export const helperMenuReducer = createReducer(
    initialState,
    on(getHelperMenus, (state) => [...mockHelperMenus()]),
    on(addHelperMenu, (state, helperMenu) => [...state, helperMenu]),
    on(clearHelperMenu, (state) => [])
);

function mockHelperMenus(): HelperMenu[] {
    return [
        {
            page: 'home',
            route: '/',
            entry: 1
        },
        {
            page: 'programs',
            route: '/programs',
            entry: 1
        }
    ];
}
