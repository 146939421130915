import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { HttpProgressBarService } from 'src/app/shared/services/httpprogress.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @HostBinding('class') class = 'login';

  public user: any = {};
  public loginError: string;

  public loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.required
    ])
  });

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  private returnUrl: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public httpProgressBarService: HttpProgressBarService
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
  }

  goToForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  login() {
    this.authService
      .login({
        email: this.email.value,
        password: this.password.value,
      })
      .pipe(first())
      .subscribe((user) => {
        // navigate to reseller's dashboard
        if (user.role.id === 6) {
          const returnUrlInRouteSnapshot = this.route.snapshot.queryParams.returnUrl;
          this.returnUrl = (returnUrlInRouteSnapshot && returnUrlInRouteSnapshot !== '/') ? returnUrlInRouteSnapshot : '/reseller';
        }
        this.router.navigateByUrl(this.returnUrl);
      }, (err) => {
        const errMsg = err.error.message;
        this.loginError = errMsg ? errMsg : 'Username or password is incorrect. Please try again.';
        if (this.loginError === 'Internal system error') {
          this.loginError = 'Login or password is incorrect.';
        }
      });
  }
}
