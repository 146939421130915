import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';

import { ProfileComponent } from './components/profile/profile.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomainDialogComponent } from './dialogs/domain/domain.dialog';
import { HeaderComponent } from './components/global/header/header.component';
import { ApplicationComponent } from './components/global/application/application.component';
import { ControlOptions } from './core/models/Control';
import { DatePipe } from '@angular/common';
import { UserInvitationComponent } from './components/user-invitations/user-invitation.component';
import { AuditComponent } from './components/audit/audit.component';
import { AuthModule } from './auth/auth.module';
import { SignupPageComponent } from './signup/components/signup.component';
import { ResellerHeaderComponent } from './components/global/reseller-header/reseller-header.component';
import { UIHelperComponent } from './components/global/ui-helper/ui-helper.component';
import { CustomersInterceptor } from './core/interceptors/customers.interceptor';
import { StoreModule } from '@ngrx/store';
import { helperMenuReducer } from './Store/Reducers/helper-menu.reducer';
import { programFiltersReducer } from './Store/Reducers/programs.filter.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpInterceptorService } from './core/interceptors/http.interceptor.service';
import { MaterialModule } from './material.module';
import { ResellerApplicationComponent } from './components/global/reseller-application/reseller-application.component';

@NgModule({
  imports: [
    BrowserModule,
    AuthModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatListModule,
    MatChipsModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBadgeModule,
    NgxDropzoneModule,
    FlexLayoutModule,
    AppRoutingModule,
    NgxStripeModule.forRoot(environment.stripe.publicKey),
    MatCheckboxModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    OverlayModule,
    MatProgressBarModule,
    MaterialModule,
    /*StoreModule.forRoot(reducers, {
      metaReducers
    }),*/
    StoreModule.forRoot({
      helperMenus: helperMenuReducer,
      programFilters: programFiltersReducer
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  declarations: [
    AppComponent,
    ProfileComponent,
    DomainDialogComponent,
    HeaderComponent,
    ResellerHeaderComponent,
    ApplicationComponent,
    ResellerApplicationComponent,
    UserInvitationComponent,
    AuditComponent,
    SignupPageComponent,
    UIHelperComponent
  ],
  providers: [
    FormBuilder,
    ControlOptions,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey,
      } as RecaptchaSettings
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DomainDialogComponent,
  ],
})
export class AppModule { }
