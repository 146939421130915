import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { PaymentMethod, StripeCardElementOptions, StripeElementsOptions, StripeError } from '@stripe/stripe-js';
import { AuthService } from '../../auth/services/auth.service';
import { GeographicalDataService } from '../services/geographical-data.service';
import { CountryInterface } from '../interfaces/country.interface';
import { TokenService } from '../services/token.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-signup-page-container',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.css']
})
export class SignupPageComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  private pageTitle: string;
  private pagePath: string;

  constructor(private stripeService: StripeService, private authService: AuthService,
              private geographicalDataService: GeographicalDataService,
              private tokenService: TokenService,
              private router: Router
              ) {
    router.events.subscribe((event ) => {
      const dataLayer = (window as any).dataLayer;
      if (event instanceof NavigationEnd && event?.url === '/signup') {
        if (typeof dataLayer !== undefined) {
          (window as any).dataLayer.push({
            event : 'pageview',
            pagePath : this.pagePath || '/signup',
            pageTitle : this.pageTitle || 'Signup page'
          });
        }
      }
    });
  }

  public user: any = {};
  public signupError: string;

  public accountType = '1'; // 1 free, 2 pro, 3 enterprise;
  public formSubmitted = false;
  public showLoading = false;
  public showForms = true;
  public showResponseMessage = false;
  public showSuccessMessage = false;
  public errorMessage = '';
  public stripeToken = '';
  public country_list: CountryInterface;
  public list_of_states: CountryInterface;
  public country_code = '';
  public state_code = '';
  public list_of_cities: CountryInterface;

  public pass_missmatch = false;

  public billing_country_select;
  public billing_state_select;
  public billing_city_select;

  public billing_country_error = false;

  // public billing_country;

  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#8E8E8E'
        }
      }
    }
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  // First name,
  // last name,
  // email address,
  // customer name
  // password
  // password confirmation,
  // required checkbox agreeing to TOS
  // Phone
  // Billing Country
  // Billing Address
  // Billing Address 2 *not required
  // Billing City
  // Billing State/ Province
  // Billing Zip /Postcode

  public signUpForm = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    tos_agreed: new FormControl(false, [Validators.required, Validators.requiredTrue]),

    email: new FormControl('', [Validators.required, Validators.email]),

    customer_name: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    confirm_password: new FormControl('', [Validators.required]),

    accountType: new FormControl('', [Validators.required]),

    phone: new FormControl('', [Validators.required]),
    billing_country: new FormControl('', [Validators.required]),
    billing_address_line1: new FormControl('', [Validators.required]),
    billing_address_line2: new FormControl(''),
    billing_city: new FormControl('', [Validators.required]),
    billing_state: new FormControl('', [Validators.required]),
    billing_postcode: new FormControl('', [Validators.required]),
    recaptchaReactive: new FormControl(null, [Validators.required])
  });
  private captchaResponse: string;

  get first_name() {
    return this.signUpForm.get('first_name');
  }
  get last_name() {
    return this.signUpForm.get('last_name');
  }
  get customer_name() {
    return this.signUpForm.get('customer_name');
  }
  get email() {
    return this.signUpForm.get('email');
  }
  get password() {
    return this.signUpForm.get('password');
  }
  get confirm_password() {
    return this.signUpForm.get('confirm_password');
  }

  get phone() {
    return this.signUpForm.get('phone');
  }
  get billing_country() {
    return this.signUpForm.get('billing_country');
  }
  get billing_address_line1() {
    return this.signUpForm.get('billing_address_line1');
  }
  get billing_address_line2() {
    return this.signUpForm.get('billing_address_line2');
  }
  get billing_city() {
    return this.signUpForm.get('billing_city');
  }
  get billing_state() {
    return this.signUpForm.get('billing_state');
  }
  get billing_postcode() {
    return this.signUpForm.get('billing_postcode');
  }

  get tos_agreed() {
    return this.signUpForm.get('tos_agreed');
  }

  get recaptchaReactive() {
    return this.signUpForm.get('recaptchaReactive');
  }

  ngOnInit() {
    // this.paymentMethods.error = false;
    // this.paymentMethods.errorMessage = '';
  }

  createToken(): void {
    const name = this.signUpForm.get('first_name').value + ' ' + this.signUpForm.get('last_name').value;
    const address_line1 = this.signUpForm.get('billing_address_line1').value;
    const address_line2 = this.signUpForm.get('billing_address_line2').value;
    const address_city = this.signUpForm.get('billing_city').value;
    const address_state = this.signUpForm.get('billing_state').value;
    const address_zip = this.signUpForm.get('billing_postcode').value;
    const address_country = this.signUpForm.get('billing_country').value; // MUST BE TWO CHAR CODE
    this.stripeService.createToken(this.card.element, { name }).subscribe((result) => {
      if (result.token) {
        // Use the token
        console.log(result.token.id);
        this.stripeToken = result.token.id;
        this.signUpAPICall();
      } else if (result.error) {
        // Error creating the token
        console.log(result.error.message);
      }
    });
  }

  loadStatesForCountry(event) {
    this.country_code = event.value;
    this.geographicalDataService.getStates(event.value).subscribe((data) => {
      this.list_of_states = data;
    });
    this.signUpForm.get('billing_country').setValue(this.country_code);
    this.signUpForm.get('billing_state').setValue(null);
    this.signUpForm.get('billing_city').setValue(null);
  }

  loadCitiesForState(event) {
    this.state_code = event.value;
    this.geographicalDataService.getCities(this.country_code, event.value).subscribe((data) => {
      this.list_of_cities = data;
    });
    this.signUpForm.get('billing_state').setValue(this.state_code);
  }
  setCity(event) {
    this.signUpForm.get('billing_city').setValue(event.value);
  }

  tryAgain() {
    this.showResponseMessage = false;
    this.showLoading = false;
    this.showForms = true;
    this.signUpForm.get('password').setValue(null);
    this.signUpForm.get('confirm_password').setValue(null);
  }

  signUp() {
    this.formSubmitted = true;
    if (this.signUpForm.get('password').value !== this.signUpForm.get('confirm_password').value) {
      this.pass_missmatch = true;
      this.formSubmitted = false;
      this.signUpForm.get('confirm_password').setErrors({ notSame: true });
      return;
    }

    // Here we will submit the event to GTM
    const dataLayer = (window as any).dataLayer;
    if (dataLayer) {
      (window as any).dataLayer.push({
        event: 'registrationComplete',
        pagePath: '/signup',
        pageTitle: 'Signup page',
        buttonId: 'registrationComplete'
      });
    }

    if (this.accountType !== '1') {
      if (
        !this.tos_agreed.invalid &&
        !this.first_name.invalid &&
        !this.last_name.invalid &&
        !this.email.invalid &&
        !this.customer_name.invalid &&
        !this.password.invalid &&
        !this.confirm_password.invalid &&
        !this.billing_country.invalid &&
        !this.billing_state.invalid &&
        !this.billing_city.invalid &&
        !this.billing_address_line1.invalid &&
        !this.phone.invalid &&
        !this.billing_postcode.invalid &&
        !this.recaptchaReactive.invalid
      ) {
        this.stripeService
          .createPaymentMethod({
            type: 'card',
            card: this.card.element
          })
          .subscribe((result: { paymentMethod?: PaymentMethod; error?: StripeError }) => {
            if (result.error) {
              // this.paymentMethods.error = true;
              // this.paymentMethods.errorMessage = result.error.message;
              return;
            }
          });
        this.createToken();
      }
    } else {
      if (
        !this.tos_agreed.invalid &&
        !this.first_name.invalid &&
        !this.last_name.invalid &&
        !this.email.invalid &&
        !this.customer_name.invalid &&
        !this.password.invalid &&
        !this.confirm_password.invalid &&
        !this.recaptchaReactive.invalid
      ) {
        this.signUpAPICall();
      }
    }
  }

  getCountriesData() {
    if (this.accountType !== '1') {
      this.geographicalDataService.getAllCountries().subscribe((data) => {
        this.country_list = data;
      });
    }
  }

  signUpAPICall() {
    this.showLoading = true;
    this.showForms = false;
    if (this.accountType !== '1') {
      this.authService
        .signUp({
          account_type: this.accountType,
          first_name: this.first_name.value,
          last_name: this.last_name.value,
          customer_name: this.customer_name.value,
          email: this.email.value,
          password: this.password.value,
          confirm_password: this.confirm_password.value,
          phone: this.phone.value,
          billing_country: this.billing_country.value,
          billing_address_line1: this.billing_address_line1.value,
          billing_address_line2: this.billing_address_line2.value,
          billing_city: this.billing_city.value,
          billing_state: this.billing_state.value,
          billing_postcode: this.billing_postcode.value,
          stripe_token: this.stripeToken,
          tos_agreed: this.tos_agreed.value,
          recaptchaReactive: this.recaptchaReactive.value
        })
        .subscribe(
          (resp) => this.handleResponse(resp),
          (resp) => this.handleResponse(resp)
        );
    } else {
      this.authService
        .signUp({
          account_type: this.accountType,
          first_name: this.first_name.value,
          last_name: this.last_name.value,
          customer_name: this.customer_name.value,
          email: this.email.value,
          password: this.password.value,
          confirm_password: this.confirm_password.value,
          tos_agreed: this.tos_agreed.value,
          recaptchaReactive: this.recaptchaReactive.value
        })
        .subscribe(
          (resp) => this.handleResponse(resp),
          (resp) => this.handleResponse(resp)
        );
    }
  }

  handleResponse(resp) {
    if (resp.success === true) {
      this.showLoading = false;
      this.showSuccessMessage = true;
    } else if (resp.message === 'User already exists') {
      this.showLoading = false;
      this.errorMessage = 'A user with the email was already found in the system!';
      this.showResponseMessage = true;
    } else {
      this.showLoading = false;
      this.errorMessage = 'We could not create your account at this moment. ' + resp.message;
      this.showResponseMessage = true;
    }
  }

  async resolved(captchaResponse: string) {
    await this.sendTokenToBackend(captchaResponse);
  }

  sendTokenToBackend(tok) {
    this.tokenService.sendToken(tok).subscribe(
      data => {
        // console.log('data: ', data);
      },
      err => {
        // console.log('err: ', err);
      },
      () => {}
    );
  }
}
