import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/signup/signup.component';
import { ActivateAccountComponent } from './components/activate-account/activate.account.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'create-your-account/:token',
    component: SignUpComponent,
  },
  {
    path: 'forgot-password',
    canActivate: [AuthGuard],
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-your-password/:token',
    canActivate: [AuthGuard],
    component: ResetPasswordComponent,
  },
  {
    path: 'activate-your-account/:token',
    canActivate: [AuthGuard],
    component: ActivateAccountComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
