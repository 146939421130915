import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Domain } from '../models/Domain';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DomainService {
  public domains: Domain[];
  public domain: Domain;

  constructor(private http: HttpClient) { }

  getAll(programId: string, params?: any) {
    return this.http
      .get<any>(`${environment.apiUrl}/programs/${programId}/domains`, { params })
      .pipe(map(domains => domains));
  }

  addDomain(programId: string, data: any) {
    return this.http
      .post<any>(`${environment.apiUrl}/programs/${programId}/domains`, data)
      .pipe(map(result => result));
  }

  updateDomain(programId: string, domainId: string, data: any) {
    return this.http
      .put<any>(`${environment.apiUrl}/programs/${programId}/domains/${domainId}`, data)
      .pipe(map(result => result));
  }

  deleteDomain(programId: string, domainId: string) {
    return this.http
      .delete<any>(`${environment.apiUrl}/programs/${programId}/domains/${domainId}`)
      .pipe(map(result => result));
  }
}
