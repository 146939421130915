<mat-toolbar class="toolbar">
  <a [routerLink]="['/']" class="app-name">
    OpsEase
  </a>

  <div class="spacer"></div>

  <ng-container>
    <div *ngIf="customers.length > 0">
      <mat-menu #resellerMenu="matMenu">
        <button mat-menu-item *ngFor="let customer of customers" (click)="selectCustomer(customer)">
          {{ customer.name }}
        </button>
      </mat-menu>

      <div class="d-inline-flex align-center">
      <span class="user" [matMenuTriggerFor]="resellerMenu">
        <h2 class="select-customer">Customers</h2>
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
      </div>
    </div>
  </ng-container>

  <mat-menu #userMenu="matMenu">
    <button
      mat-menu-item routerLink="/personal-settings"
    >
      Personal settings
    </button>

    <ng-container *ngIf="customerId">

      <button mat-menu-item [routerLink]="['/admin/customers/customer-settings']">
        Customer settings
      </button>

      <button  mat-menu-item (click) = "navigateToResellerDashboard()">
        Reseller Dashboard
      </button>

      <button mat-menu-item routerLink="/admin/users">
        Users
      </button>

      <button mat-menu-item routerLink="/admin/audit">
        Audit log
      </button>

      <button mat-menu-item (click) = "getHelperMenus()" *ngIf = "!isHelperMenusPresent">
        Get Helper Menus
      </button>
      
      <a mat-menu-item href="https://opsease.zendesk.com/" target="_blank">
        Support
      </a>
    </ng-container>

    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>

  <div class="d-inline-flex align-center">
    <a *ngIf="customerId"
      [routerLink]="['/controls/pending']"
      class="notification-bell"
      (click) = "resetNotificationsCount()"
    >
      <mat-icon [matBadge] = "notificationsCount | async" matBadgeColor = "warn" >notifications</mat-icon>
      <!--<mat-icon *ngIf = "showEmptyBadge">notifications</mat-icon>-->
    </a>
    <span class="user" [matMenuTriggerFor]="userMenu">
      {{ currentUser.name }}

      <mat-icon>arrow_drop_down</mat-icon>
    </span>
  </div>
</mat-toolbar>
