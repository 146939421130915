<div *ngIf="httpProgressBarService.isLoading | async">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<section class="section">
  <mat-card class="reset-password-box">
    <h1>Reset Your Password</h1>
    <form class="reset-password-form" [formGroup]="resetPasswordForm" (ngSubmit)="reset()">
      <mat-error class="reset-password-error" *ngIf="resetPasswordError">{{ resetPasswordError }}</mat-error>
      <mat-form-field appearance="outline">
        <mat-label>New Password</mat-label>
        <input matInput type="password" name="password" formControlName="password" required id = "reset-search"/>
        <mat-error *ngIf="password.invalid && (password.dirty || password.touched) && password.errors.required">
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <button class="reset-password-button" type="submit" mat-raised-button color="primary" [disabled]="!resetPasswordForm.valid">
        Submit
      </button>
      <div *ngIf="resetPasswordSuccess" class="reset-password-success">{{ resetPasswordSuccess }}</div>
    </form>
  </mat-card>
</section>

