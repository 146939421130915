import { Observable, of } from 'rxjs';
import { AuthStrategy } from './auth.strategy';
import { User } from '../../core/models/User';

export class SessionAuthStrategy implements AuthStrategy<User> {
  doLoginUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  doLogoutUser(): void {
    localStorage.removeItem('user');
  }

  getCurrentUser(): Observable<User> {
    const user = this.getUser();

    if (user) {
      return of(JSON.parse(user));
    }

    return of(undefined);
  }

  getUser() {
    return localStorage.getItem('user');
  }
}
