import {Injectable} from '@angular/core';
import {User} from './User';
import {Domain} from './Domain';
import {Frequencies} from '../enums/frequencies.enum';

export interface IControlOptions {
  value: number;
  viewValue: string;
}

export interface ControlInheritance {
  id: string;
  parent_control: string;
  child_control: string;
  direct_parent_control: string;
  created_at ?: any;
  updated_at ?: any;
}

export class Control {
  public id: string;
  public number: string;
  public domain_id: string;
  public wording: string;
  public date: Date;
  public status_id: number;
  public testing: string;
  public testing_instructions: string;
  public evidence: string;
  public frequency_id: number;
  public owner_id: string;
  public owner: User;
  public reviewer_id: string;
  public reviewer: User;
  public remediation_status: string;
  public remediation_due_date: Date;
  public remediation_text: string;
  public start_date?: Date;
  public end_date?: Date;
  public assignment?: any;
  public ownerType?: string;
  public invitationEmail?: string;
  public domain?: Domain;
  public frequency?: any;
  public status?: any;
  public files?: any;
  public notifications?: any;
  public reviewed: false;
  public slack?: boolean;
  public jira?: boolean;
  public allow_slack?: boolean;
  public allow_jira?: boolean;
  public parentControl ?: Control;
  public controlInheritance ?: ControlInheritance;
  public completedControls ?: number;
  public expectedCompletions?: number;
  public incompleteControls?: number;
}

export interface ControlInterface {
  id: string;
  number: string;
  domain_id: string;
  wording: string;
  date: Date;
  status_id: number;
  testing: string;
  testing_instructions: string;
  evidence: string;
  frequency_id: number;
  owner_id: string;
  owner: User;
  reviewer_id: string;
  reviewer: User;
  remediation_status: string;
  remediation_due_date: Date;
  remediation_text: string;
  start_date?: Date;
  end_date?: Date;
  assignment?: any;
  ownerType?: string;
  invitationEmail?: string;
  domain?: Domain;
  frequency?: any;
  status?: any;
  files?: any;
  notifications?: any;
  reviewed: false;
  slack?: boolean;
  jira?: boolean;
  allow_slack?: boolean;
  allow_jira?: boolean;
  parentControl ?: Control;
  controlInheritance ?: ControlInheritance;
}

@Injectable()
export class ControlOptions {
  statusOptions: IControlOptions[] = [
    { value: 1, viewValue: 'Not Started' },
    { value: 2, viewValue: 'In Progress' },
    { value: 3, viewValue: 'Complete' },
    { value: 4, viewValue: 'Exception Noted' }
  ];

  frequencyOptions: IControlOptions[] = [
    { value: Frequencies.Automated, viewValue: 'Automated' },
    { value: Frequencies.Daily, viewValue: 'Daily' },
    { value: Frequencies.Weekly, viewValue: 'Weekly' },
    { value: Frequencies.Monthly, viewValue: 'Monthly' },
    { value: Frequencies.TwoMonths, viewValue: '2 Months' },
    { value: Frequencies.Quarterly, viewValue: 'Quarterly' },
    { value: Frequencies.SemiYearly, viewValue: 'Semi-Annual'},
    { value: Frequencies.Yearly, viewValue: 'Yearly' },
  ];

  remediationStatus: IControlOptions[] = [
    { value: 1, viewValue: 'In Progress' },
    { value: 2, viewValue: 'Remediated' },
    { value: 3, viewValue: 'Risk Accepted' },
  ];
}
