import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { config } from './../../core/config';
import { SessionAuthStrategy } from './session-auth.strategy';
import { User } from '../../core/models/User';

export interface AuthStrategy<T> {
  doLoginUser(data: T): void;
  doLogoutUser(): void;
  getCurrentUser(): Observable<User>;
}

export const AUTH_STRATEGY = new InjectionToken<AuthStrategy<any>>('AuthStrategy');

export const authStrategyProvider = {
  provide: AUTH_STRATEGY,
  useFactory: () => {
    switch (config.auth) {
      case 'session':
        return new SessionAuthStrategy();
    }
  }
};
