<div *ngIf="httpProgressBarService.isLoading | async">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
  <ng-template [ngIf]="!isSentEmail">
    <section class="section">
      <mat-card class="forgot-password-box">
          <h1>Recover Your Password</h1>
          <form class="forgot-password-form" [formGroup]="forgotPasswordForm" (ngSubmit)="sendEmail()">
            <div class="enter-email">
              <p>Enter your email address and we’ll send you an email with further instructions for resetting your password.</p>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Email Address</mat-label>
              <input matInput name="email" formControlName="email" required id = "email-search"/>
              <mat-error *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required">
                Email is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
            <button class="forgot-password-button" type="submit" mat-raised-button color="primary" [disabled]="!forgotPasswordForm.valid">
              Submit
            </button>
          </form>
      </mat-card>
    </section>
  </ng-template>

<ng-template [ngIf]="isSentEmail">
  <section class="section">
    <mat-card class="forgot-password-box">
      <div class="forgot-password-success">{{ sendEmailSuccess }}</div>
    </mat-card>
  </section>
</ng-template>
