import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResellersService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any>(`${environment.apiUrl}/resellers`).pipe(map(response => {
      return response.results;
    }));
  }

  getAllResellerUsers() {
    return this.http.get<any>(`${environment.apiUrl}/resellers/users`).pipe(map(response => {
      return response.results;
    }));
  }

  getSelectedResellerUser() {
    return this.http.get<any>(`${environment.apiUrl}/resellers/customer`).pipe(map(response => {
      return response;
    }));
  }

  addCompany(company: any) {
    return this.http.post<any>(`${environment.apiUrl}/resellers`, company).pipe(map(u => u));
  }

  addReseller(reseller: any) {
    return this.http.post<any>(`${environment.apiUrl}/resellers/users`, reseller).pipe(map(u => u));
  }

  addResellerByCustomer(reseller: any) {
    return this.http.post<any>(`${environment.apiUrl}/customers/invite-reseller`, reseller).pipe(map(u => u));
  }

  deleteResellerCompany(id) {
    return this.http
      .delete<any>(`${environment.apiUrl}/resellers/${id}`)
      .pipe(map(result => result));
  }
}
