import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CountryInterface } from '../interfaces/country.interface';

@Injectable({
  providedIn: 'root'
})
export class GeographicalDataService {

  constructor(private http: HttpClient) { }

  getAllCountries() {
    return this.http.get<CountryInterface>(`${environment.apiUrl}/get-all-countries`).pipe(map(countries => {
      return countries;
    }));
  }

  getStates(countryCode: string) {
    return this.http.get<CountryInterface>(`${environment.apiUrl}/get-country-states?countryCode=${countryCode}`).pipe(map(states => {
      return states;
    }));
  }

  getCities(countryCode: string, stateCode: string) {
    return this.http.get<CountryInterface>(`${environment.apiUrl}/get-state-cities?countryCode=${countryCode}&stateCode=${stateCode}`)
      .pipe(map(cities => {
      return cities;
    }));
  }

}
