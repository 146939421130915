<app-reseller-header *ngIf="currentUser && currentUser.role.id === 6"></app-reseller-header>
<mat-sidenav-container *restrictAccess="['reseller']" class="main" (backdropClick)="close()">
  <mat-sidenav *ngIf="!isAccountDeactivated" #sidenav mode="side" opened="true" disableClose
    (mouseenter)="showSidebar()" (mouseleave)="hideSidebar()" (keydown.escape)="hideSidebar()">
    <mat-nav-list>
      <mat-list-item routerLink="/reseller">
        <mat-icon mat-list-icon>signal_cellular_alt</mat-icon>
        <h4 mat-line>Dashboard</h4>
      </mat-list-item>
      <mat-list-item routerLink="/reseller/pending-controls">
        <mat-icon mat-list-icon>pending_actions</mat-icon>
        <h4 mat-line>Pending Controls</h4>
      </mat-list-item>
      <mat-list-item routerLink="/reseller/exceptions">
        <mat-icon mat-list-icon>report</mat-icon>
        <h4 mat-line>Risks/Incidents</h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content">
    <div *ngIf="httpProgressBarService.isLoading | async">
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>