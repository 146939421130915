import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Customer } from '../core/models/Customer';

type Channel = 'slack | jira';
@Injectable({ providedIn: 'root' })
export class CustomerService {
  public customers: Customer[];
  public customer: Customer;
  private currentCustomerSubject: BehaviorSubject<Customer>;
  public currentCustomer: Observable<Customer>;

  constructor(private http: HttpClient) {
    this.currentCustomerSubject = new BehaviorSubject<Customer>(JSON.parse(localStorage.getItem('currentCustomer')));
    this.currentCustomer = this.currentCustomerSubject.asObservable();
  }

  public get currentCustomerValue(): Customer {
    return this.currentCustomerSubject.value;
  }

  getAll() {
    return this.http.get<any>(`${environment.apiUrl}/customers`).pipe(map(response => {
      return response;
    }));
  }

  getCustomersForReseller() {
    return this.http.get<any>(`${environment.apiUrl}/customers/reseller`).pipe(map(response => {
      return response;
    }));
  }

  getCustomer(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/customers/${id}`).pipe(map(customer => {
      return customer;
    }));
  }

  addCustomer(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/customers`, data).pipe(map(record => {
      return record;
    }));
  }

  updateCustomer(id: string, data: any) {
    return this.http.put<any>(`${environment.apiUrl}/customers/${id}`, data).pipe(map(response => {
      return response;
    }));
  }

  deleteCustomer(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}/customers/${id}`).pipe(map(response => {
      return response;
    }));
  }

  getUsers(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/customers/${id}/users`).pipe(map(customer => {
      return customer;
    }));
  }

  getPaymentMethods(customerId: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/customers/${customerId}/cards`)
      .pipe(map(response => response));
  }

  addPaymentMethod(customerId: string, paymentMethodId: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/customers/${customerId}/cards`, { paymentMethodId })
      .pipe(map(response => response));
  }

  getSubscriptions(customerId: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/customers/${customerId}/subscriptions`)
      .pipe(map(response => response));
  }

  createSubscription(customerId: string, subscription: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/customers/${customerId}/subscriptions`, { subscription })
      .pipe(map(response => response));
  }

  updateSubscription(customerId: string, subscription: string) {
    return this.http
      .put<any>(`${environment.apiUrl}/customers/${customerId}/subscriptions`, { subscription })
      .pipe(map(response => response));
  }

  removeSubscription(customerId: string, subscriptionId: string) {
    return this.http
      .delete<any>(`${environment.apiUrl}/customers/${customerId}/subscriptions/${subscriptionId}`)
      .pipe(map(response => response));
  }

  setActiveCustomer(customer: Observable<any>) {
    localStorage.setItem('currentCustomer', JSON.stringify(customer));
  }

  getActiveCustomer(): Observable<any> {
    const currentCustomer: any = localStorage.getItem('currentCustomer');

    return JSON.parse(currentCustomer);
  }
  editNotificationChannels(customerId: string, action: string, notificationsBoard: string) {
    return this.http.get(`${environment.apiUrl}/customers/turn-off-notification/${customerId}/
    ${action}/${notificationsBoard}`).pipe(map(response => response));
  }
  getNotificationChannel(customerId: string, channel: string, notificationsBoard: string) {
    return this.http.get(`${environment.apiUrl}/customers/notification-options`,
      { params: { customerId, channel, notificationsBoard } }).pipe(map(response => response));
  }
}
