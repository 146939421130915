import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuditService } from '../core/services/audit.service';
import { Audit } from '../core/models/Audit';
import { catchError, finalize } from 'rxjs/operators';

export class AuditDataSource implements DataSource<Audit> {
  private auditSubject = new BehaviorSubject<Audit[]>([]);
  private countSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public count = this.countSubject.asObservable();

  constructor(private auditService: AuditService) {}

  connect(): Observable<Audit[]> {
    return this.auditSubject.asObservable();
  }

  disconnect(): void {
    this.auditSubject.complete();
    this.loadingSubject.complete();
  }

  load(pageIndex = 1, pageSize = 20, customer = null) {
    this.loadingSubject.next(true);

    this.auditService
      .index(pageIndex, pageSize, customer)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((response) => {
        this.countSubject.next(response.pagination.total);
        this.auditSubject.next(response.data);
      });
  }
}
